import React from 'react'
import { useTranslation } from 'react-i18next'
import { motion } from "framer-motion";

import {
  FacebookIcon,
  InstagramIcon,
  TelephoneIcon,
  WhatappIcon,
  YoutubeIcon
} from '../assets/socialmedia'
import {
  GlicorsaComplete,
  GlicorsaCompleteWhite
} from '../assets/logo'

import ContactForm from './ui/contact-form';


const SocialMediaIcons = [
  { icon: FacebookIcon, url: '/' },
  { icon: InstagramIcon, url: '/' },
  { icon: YoutubeIcon, url: '/' },
  { icon: WhatappIcon, url: '/' },
  { icon: TelephoneIcon, url: '/' },
]



const Footer = ({ classes, form, footerTextWhite, footerBackground }) => {
  const d = new Date();
  const year = d.getFullYear()
  const [t] = useTranslation('global');

  return (
    <div className={` ${footerBackground ? `${footerBackground} bg-no-repeat bg-cover bg-fixed bg-center` : ''} p-5 justify-between items-center text-beige ${classes} ${footerTextWhite ? 'text-white' : 'text-black'}`}>
      <div className='flex justify-center'>
        {form ? <></> : <ContactForm />}
      </div>

      <motion.div
        className='flex justify-center items-end p-8 animate-fade animate-duration-500'>
        <a href='/'>
          <motion.img
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{
              duration: 0.3,
              ease: [0, 0.71, 0.2, 1.01],
              scale: {
                type: "linear",
                damping: 5,
                stiffness: 100,
                restDelta: 0.001
              }
            }}
            src={footerTextWhite ? GlicorsaCompleteWhite : GlicorsaComplete} alt='glicorsa' className='w-44 h-auto' />
        </a>
      </motion.div>

      <div className='h-0.5 w-full bg-black my-2'></div>

      <div>
        <div className='flex justify-end'>
          {SocialMediaIcons.map((item, index) => (
            <a key={index} href={item.url} target='_blank' rel='noopener noreferrer'>
              <img src={item.icon} alt={`icon-${index}`} className={`w-6 h-auto mr-3 hover:animate-jump duration-200 rounded-full p-0.5 ${footerTextWhite ? 'bg-white' : ''}`} />
            </a>
          ))}
        </div>
        <div>
          <a href='/contact#info' className=''>
            {t('contact.contact.title')}
          </a>
        </div>
        <div className='underline flex-col text-sm py-4'>
          <div><a href='/legal/privacy-and-cookies-notice'> {t("footer.privacy")} </a></div>
          <div><a href='/legal/terms-and-conditions-of-use'> {t("footer.terms")} </a></div>
        </div>
        <div className='text-xs'>© {year} Glicorsa. All rights reserved.</div>
      </div>
    </div>
  )
}

export default Footer
