import React, { useEffect, useState } from 'react';

import ButtonLanguaje from '../button-language';
import NavItem from './nav-item';

import { HamburgerIcon, HamburgerIconWhite } from '../../assets/navbar';

import { useTranslation } from 'react-i18next';
import { ChevronDown } from 'lucide-react'
import { motion } from "framer-motion";

const MobileNavBar = ({
  currentPath,
  navItems,
  menuVisible,
  toggleMenu,
  textwhite
}) => {
  const [t] = useTranslation('global');

  const [isMounted, setIsMounted] = useState(false)


  const itemVariants = {
    open: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 300, damping: 24 }
    },
    closed: { opacity: 0, y: -20, transition: { duration: 0.2 } }
  };
  const [isOpen, setIsOpen] = useState(false);

  const Items = {
    home: { href: '/', text: 'header.home' },
    aboutus: {
      href: '/aboutus',
      text: 'header.aboutus',
      company: {
        href: '/aboutus',
        text: 'header.aboutus',
      },
      team: {
        href: '/aboutus/team',
        text: 'header.team',
      }
    },
    services: { href: '/services', text: 'header.services' },
    products: { href: '/products', text: 'header.products' },
    contact: { href: '/contact', text: 'header.contact' },
  };

  useEffect(() => {
    setIsMounted(true)
  }, [])

  if (!isMounted) { return null }

  return (
    <div className={`xl:hidden ${menuVisible ? 'w-full' : 'w-fit'} text-white`}>
      <div role="button" onClick={toggleMenu}>
        <img src={textwhite ? HamburgerIconWhite : HamburgerIcon} alt="menu" className="w-6 h-auto" />
      </div>
      <div
        className={`xl:hidden z-50 w-full animate-fade animate-duration-700 p-1
        ${menuVisible ? '' : 'hidden'}`}
      >
        <div className={`xl:block ${menuVisible ? 'translate-x-0' : ''} duration-500 flex flex-col items-center `}>
          <div className='flex flex-col justify-evenly min-h-[25rem]'>
            <NavItem key={Items.home.text} href={Items.home.href} text={Items.home.text} currentPath={currentPath} />
            <motion.div
              initial={false}
              animate={isOpen ? "open" : "closed"}
              className='group inline-block'
              onClick={() => setIsOpen(!isOpen)}
            >
              <motion.button
                whileTap={{ scale: 0.97 }}
                className='flex gap-1 items-center text-xl'>
                <p>{t(Items.aboutus.text)}</p>
                <ChevronDown />
              </motion.button>
              <motion.div className={` ${isOpen ? "block" : "hidden"} duration-100 shadow-sm z-1  rounded-sm w-20`}>
                <motion.ul
                  className='opacity-90 flex flex-col items-start p-1 w-40'
                  variants={{
                    open: {
                      clipPath: "inset(0% 0% 0% 0% round 10px)",
                      transition: {
                        type: "spring",
                        bounce: 0,
                        duration: 0.7,
                        delayChildren: 0.3,
                        staggerChildren: 0.05
                      }
                    },
                    closed: {
                      clipPath: "inset(10% 50% 90% 50% round 10px)",
                      transition: {
                        type: "spring",
                        bounce: 0,
                        duration: 0.3
                      }
                    }
                  }}
                  style={{ pointerEvents: isOpen ? "auto" : "none" }}
                >
                  <motion.li
                    id='about'
                    variants={itemVariants}
                    className='hover:text-slate-500 px-1'
                  >
                    <NavItem key={Items.aboutus} href={Items.aboutus.href} text={Items.aboutus.text} currentPath={currentPath} />
                  </motion.li>

                  <motion.li
                    id='about'
                    variants={itemVariants}
                    className='hover:text-slate-500 px-1'
                  >
                    <NavItem key={Items.aboutus.team} href={Items.aboutus.team.href} text={Items.aboutus.team.text} currentPath={currentPath} />
                  </motion.li>

                </motion.ul>
              </motion.div>
            </motion.div>
            <NavItem key={Items.services.text} href={Items.services.href} text={Items.services.text} currentPath={currentPath} />

            <NavItem key={Items.products.text} href={Items.products.href} text={Items.products.text} currentPath={currentPath} />
            <NavItem key={Items.contact.text} href={Items.contact.href} text={Items.contact.text} currentPath={currentPath} />

          </div>
          <div className="p-7">
            <ButtonLanguaje />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNavBar;
