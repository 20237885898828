import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NavItem = ({ href, text, currentPath, textwhite = false, dropdownItems }) => {
  const [t] = useTranslation('global');

  return (
    <div>
      <Link
        to={href}
        className={`text-xl relative ${textwhite ? 'after:bg-white' : 'after:bg-black'} after:absolute after:h-0.5 after:w-0 after:bottom-0 after:left-0 hover:after:w-full after:transition-all after:duration-300 cursor-pointer
          ${currentPath === href && 'text-gray-400'}`}
      >
        {t(text)}
      </Link>
    </div>
  );
};

export default NavItem;
