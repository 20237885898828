import React from 'react';
import { useLocation } from 'react-router-dom';

import DesktopNavBar from './desktop-navbar';
import MobileNavBar from './mobile-navbar';

import useMenuVisibility from '../../hooks/useMenuVisibility';

const Navbar = ({ textwhite = false, id }) => {
  const { menuVisible, toggleMenu } = useMenuVisibility();
  const location = useLocation();
  const currentPath = location.pathname;

  const navItems = [
    { href: '/', text: 'header.home' },
    { href: '/aboutus', text: 'header.aboutus' },
    { href: '/services', text: 'header.services' },
    { href: '/products', text: 'header.products' },
    { href: '/contact', text: 'header.contact' },
  ];

  const Items = {
    home: { href: '/', text: 'header.home' },
    aboutus: { href: '/aboutus', text: 'header.aboutus' },
    services: { href: '/services', text: 'header.services' },
    products: { href: '/products', text: 'header.products' },
    contact: { href: '/contact', text: 'header.contact' },
  };

  return (
    <div
      id={id}
      className={`
        backdrop-blur-sm p-4 top-0 z-10 min-w-full bg-black  xl:bg-none xl:bg-opacity-0
        ${textwhite ? 'text-white' : 'text-black'}
        duration-500 xl:h-36 md:h-14 xl:absolute fixed shadow-lg xl:shadow-none
        ${menuVisible ? 'h-screen md:h-screen xl:h-screen bg-opacity-60' : 'h-14 md:h-14 bg-opacity-30'}
      `}
    >
      <MobileNavBar navItems={navItems} currentPath={currentPath} menuVisible={menuVisible} toggleMenu={toggleMenu} textwhite={textwhite} />
      <DesktopNavBar navItems={Items} currentPath={currentPath} textwhite={textwhite} />
    </div>
  );
};

export default Navbar;
