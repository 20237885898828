import React, { useEffect, useState } from 'react'
import { motion } from "framer-motion";

import { useLanguage } from '../providers/languaje-provider';


const ButtonLanguaje = () => {
  const { flag, changeLanguage, languageIcons, lng } = useLanguage();

  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  },[])

  const itemVariants = {
    open: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 300, damping: 24 }
    },
    closed: { opacity: 0, y: 20, transition: { duration: 0.2 } }
  };

  const [isOpen, setIsOpen] = useState(false);

  const closeMenu = () => {
    setIsOpen(false);
  };

  if(!isMounted) {return null}

  return (
    <motion.div
      initial={false}
      animate={isOpen ? "open" : "closed"}
      className='group relative inline-block text-gray-600 border-1'>
      <motion.button
        whileTap={{ scale: 0.97 }}
        onClick={() => setIsOpen(!isOpen)}
        className='p-1 cursor-pointer border-2 rounded-md flex items-center justify-evenly border-gray-600 w-20'>
        <img src={flag} alt={lng} className='w-8 h-auto mr-2' />
        <motion.div
          variants={{
            open: { rotate: 180 },
            closed: { rotate: 0 }
          }}
          transition={{ duration: 0.2 }}
          style={{ originY: 0.55 }}
        >
          <svg width="15" height="15" viewBox="0 0 20 20">
            <path d="M0 7 L 20 7 L 10 16" />
          </svg>
        </motion.div>
      </motion.button>
      <motion.div className='group-focus:block absolute shadow-sm z-1 font-bold rounded-sm w-20 overflow-auto overflow-x-hidden h-32 xl:h-96'>
        <motion.ul
          className='bg-white opacity-90'
          variants={{
            open: {
              clipPath: "inset(0% 0% 0% 0% round 10px)",
              transition: {
                type: "spring",
                bounce: 0,
                duration: 0.7,
                delayChildren: 0.3,
                staggerChildren: 0.05
              }
            },
            closed: {
              clipPath: "inset(10% 50% 90% 50% round 10px)",
              transition: {
                type: "spring",
                bounce: 0,
                duration: 0.3
              }
            }
          }}
          style={{ pointerEvents: isOpen ? "auto" : "none" }}
        >

          {Object.entries(languageIcons).map(([code, icon]) => (
            <motion.li
              id='flag'
              variants={itemVariants}
              key={code}
              className='p-1 cursor-pointer rounded-md flex items-center justify-evenly w-20 hover:bg-slate-300 hover:animate-jump duration-200'
              onClick={() => {
                changeLanguage(code, icon)
                closeMenu()
              }}
            >
              <motion.img className='w-8 h-auto' src={icon} alt={code} />
              {code}
            </motion.li>
          ))}
        </motion.ul>
      </motion.div>
    </motion.div>
  )
}

export default ButtonLanguaje
