import React, { useState } from 'react'

import { GlicorsaComplete, GlicorsaCompleteWhite } from '../../assets/logo'

import ButtonLanguaje from '../button-language'
import NavItem from './nav-item'
import { VelocityTequilaBlanco } from '../../assets/liquors/velocity'
import { MasterfinoRonExtraAnejo } from '../../assets/liquors/master'
import { VodkaRainbow } from '../../assets/liquors/warner'
import CardList from './card-list'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { ChevronDown } from 'lucide-react'
import { motion } from "framer-motion";




const DesktopNavBar = ({ currentPath, textwhite }) => {
  const [t] = useTranslation('global');

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const itemVariants = {
    open: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 300, damping: 24 }
    },
    closed: { opacity: 0, y: 20, transition: { duration: 0.2 } }
  };
  const [isOpen, setIsOpen] = useState(false);


  const Items = {
    home: { href: '/', text: 'header.home' },
    aboutus: {
      href: '/aboutus',
      text: 'header.aboutus',
      company: {
        href: '/aboutus',
        text: 'header.aboutus',
      },
      team: {
        href: '/aboutus/team',
        text: 'header.team',
      }
    },
    services: { href: '/services', text: 'header.services' },
    products: { href: '/products', text: 'header.products' },
    contact: { href: '/contact', text: 'header.contact' },
  };
  const getProductDropdownItems = () => {
    // Lógica para obtener los elementos del dropdown del producto
    return [
      { href: '/products/velocity', text: 'Velocity', image: VelocityTequilaBlanco, type: 'Tequila' },
      { href: '/products/masterfino', text: 'Masterfino', image: MasterfinoRonExtraAnejo, type: 'Ron' },
      { href: '/products/warner', text: 'Warner & Blanc', image: VodkaRainbow, type: 'Vodka' },
      // Agrega más elementos de producto si es necesario
    ];
  };


  return (
    <nav className='hidden xl:flex items-center justify-between'>
      <a
        href='/'
        className='flex justify-start items-end w-1/3 cursor-default'>
        <img
          src={textwhite ? GlicorsaCompleteWhite : GlicorsaComplete}
          alt='logo'
          className={`h-32 cursor-pointer`} />
      </a>

      <div className='flex space-x-4 items-center justify-center text-xl w-1/3'>
        <NavItem key={Items.home.text} href={Items.home.href} text={Items.home.text} currentPath={currentPath} />

        <motion.div
          initial={false}
          animate={isOpen ? "open" : "closed"}
          className='group relative inline-block'
          onMouseEnter={() => setIsOpen(!isOpen)}
          onMouseLeave={() => setIsOpen(!isOpen)}
        >
          <motion.button
            whileTap={{ scale: 0.97 }}
            className='flex gap-1 items-center'>
            <p>{t(Items.aboutus.text)}</p>
            <ChevronDown />
          </motion.button>
          <motion.div className='group-focus:block absolute shadow-sm z-1  rounded-sm w-20'>
            <motion.ul
              className='bg-white opacity-90 flex flex-col items-start p-1 w-40'
              variants={{
                open: {
                  clipPath: "inset(0% 0% 0% 0% round 10px)",
                  transition: {
                    type: "spring",
                    bounce: 0,
                    duration: 0.7,
                    delayChildren: 0.3,
                    staggerChildren: 0.05
                  }
                },
                closed: {
                  clipPath: "inset(10% 50% 90% 50% round 10px)",
                  transition: {
                    type: "spring",
                    bounce: 0,
                    duration: 0.3
                  }
                }
              }}
              style={{ pointerEvents: isOpen ? "auto" : "none" }}
            >
              <motion.li
                id='about'
                variants={itemVariants}
                className='text-black hover:text-slate-500'
              >
                <NavItem key={Items.aboutus} href={Items.aboutus.href} text={Items.aboutus.text} currentPath={currentPath} />
              </motion.li>

              <motion.li
                id='about'
                variants={itemVariants}
                className='text-black hover:text-slate-500'
              >
                <NavItem key={Items.aboutus.team} href={Items.aboutus.team.href} text={Items.aboutus.team.text} currentPath={currentPath} />
              </motion.li>
            </motion.ul>
          </motion.div>
        </motion.div>

        <NavItem key={Items.services.text} href={Items.services.href} text={Items.services.text} currentPath={currentPath} />

        <div className='w-fit' onMouseEnter={handleDropdownToggle} onMouseLeave={handleDropdownToggle}>
          <Link
            to={'/products'}
            className={`
            flex gap-1 justify-center items-center
            ${currentPath === '/products' && 'text-gray-400'}
              text-xl relative ${textwhite ? 'after:bg-white' : 'after:bg-black'} after:absolute after:h-0.5 after:w-0 after:bottom-0 after:left-0 hover:after:w-full after:transition-all after:duration-300 cursor-pointer
              `} >
            <p>{t(Items.products.text)}</p>
            <ChevronDown />
          </Link>
          {isDropdownOpen && (
            <CardList
              currentPath={currentPath}
              items={getProductDropdownItems()}
            />
          )}
        </div>

        <NavItem key={Items.contact.text} href={Items.contact.href} text={Items.contact.text} currentPath={currentPath} />
      </div>

      <div className='w-1/3 flex justify-end'>
        <ButtonLanguaje />
      </div>
    </nav>
  )
}

export default DesktopNavBar
