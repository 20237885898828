const TeammateCard = ({image, name = 'foo', job, description}) => {
  return (
    <div className="flip-card bg-transparent w-[190px] h-[254px] perspective-1000 font-sans">
      <div className="flip-card-inner
        relative w-full h-full text-center
        transition-transform duration-800 transform-style-preserve-3d"
      >
        <div className="flip-card-front
          shadow-md absolute flex flex-col justify-between w-full h-full
          backface-hidden border border-white rounded-xl
          bg-gray-200"
        >
          <div className="aspect-square bg-red-300 rounded-t-xl ">
            <img src={image} alt='teammate' className="aspect-square object-cover rounded-t-xl"/>
          </div>
          <p className="text-xl font-extrabold text-center text-blue-600">{name}</p>
          <p>{job}</p>
        </div>

        <div className="flip-card-back
          shadow-md absolute flex flex-col justify-center w-full h-full
          backface-hidden border border-white rounded-xl
          bg-gray-200
          transform rotate-y-180"
        >
          <p className="title">{description}</p>
          <p>- {name}</p>
        </div>
      </div>
    </div>
  );
}

export default TeammateCard;
