import React from 'react';

//router
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom'

//styles
import './styles/application.scss';
import reportWebVitals from './reportWebVitals';

//i18next
import i18n from "i18next";
import { I18nextProvider } from 'react-i18next';
import { initReactI18next } from "react-i18next";
import global_en from './translations/en/global.json';
import global_fr from './translations/fr/global.json';
import global_es from './translations/es/global.json';
import global_pt from './translations/pt/global.json';
import global_al from './translations/al/global.json';
import global_zh from './translations/zh/global.json';
import global_ja from './translations/ja/global.json';

//Views
import HomeView from './views/home';
import ConctactView from './views/contact';
import AboutUsView from './views/about_us';
import ServicesView from './views/services';
import ProductsView from './views/products';
import VelocityView from './views/products/velocity';
import MasterfinoView from './views/products/masterfino';
import WarnerView from './views/products/warner';
import NotFoundView from './views/NotFoundView';
import Terms from './views/terms';
import PrivacyAndPolicy from './views/privacypolicy';
import { LanguageProvider } from './providers/languaje-provider';
import AgeConfirm from './components/cookies/age-confirm';
import AcceptCookies from './components/cookies/cookies';
import TeamView from './views/about_us/team';

const router = createBrowserRouter([
  { path: "/", element: <HomeView /> },
  { path: "/contact", element: <ConctactView /> },
  { path: "/aboutus", element: <AboutUsView /> },
  { path: "/aboutus/team", element: <TeamView /> },
  { path: "/services", element: <ServicesView /> },
  { path: "/products", element: <ProductsView /> },
  { path: "/products/velocity", element: <VelocityView /> },
  { path: "/products/masterfino", element: <MasterfinoView /> },
  { path: "/products/warner", element: <WarnerView /> },
  { path: "/legal/terms-and-conditions-of-use", element: <Terms /> },
  { path: "/legal/privacy-and-cookies-notice", element: <PrivacyAndPolicy /> },
  { path: "*", element: <NotFoundView /> }
])

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { global: global_en },
      fr: { global: global_fr },
      es: { global: global_es },
      pt: { global: global_pt },
      al: { global: global_al },
      zh: { global: global_zh },
      ja: { global: global_ja }
    },
    fallbackLng: 'en', // Set English as the fallback language
    interpolation: {
      escapeValue: false
    }
  });

// ...


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider>
      <LanguageProvider>
        <AgeConfirm />
        <AcceptCookies />
        <RouterProvider router={router} />
      </LanguageProvider>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
