import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Modal from './modal';
import Gallery from '../gallery';

const ImageCollage = ({ upright, upleft, downright, downleft }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const Images = [upright, upleft, downright, downleft];

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const ImagesFiltered = Images.filter((img) => img !== selectedImage);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <motion.div className='lg:w-1/2 flex flex-col items-center mt-4'>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Gallery images={[selectedImage, ...ImagesFiltered]} />
      </Modal>

      <div className=''>
        <div className='flex flex-row gap-1 mb-1 overflow-hidden'>
          <div className='h-32 w-3/5 lg:h-56 overflow-hidden'>
            <motion.img
              src={upright}
              alt=''
              className='h-full w-full object-cover cursor-pointer transform hover:scale-105 transition-transform duration-300'
              onClick={() => handleImageClick(upright)}
            />
          </div>
          <div className='w-2/5 aspect-square overflow-hidden'>
            <motion.img
              src={upleft}
              alt=''
              className='aspect-square h-full w-full object-cover cursor-pointer transform hover:scale-105 transition-transform duration-300'
              onClick={() => handleImageClick(upleft)}
            />
          </div>
        </div>
        <div className='flex flex-row gap-1 overflow-hidden'>
          <div className='h-32 w-2/5 lg:h-56 overflow-hidden'>
            <motion.img
              src={downright}
              alt=''
              className='h-full w-full object-cover cursor-pointer transform hover:scale-105 transition-transform duration-300'
              onClick={() => handleImageClick(downright)}
            />
          </div>
          <div className='h-32 w-3/5 lg:h-56 overflow-hidden'>
            <motion.img
              src={downleft}
              alt=''
              className='h-full w-full object-cover cursor-pointer transform hover:scale-105 transition-transform duration-300'
              onClick={() => handleImageClick(downleft)}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ImageCollage;
