import { Bruce, CascoBruce, SuitBruce } from "../../../assets/about";
import Layout from "../../../components/layout";
import TeammateCard from "../../../components/ui/teammate-card";

const TeamView = () => {
  return (
    <Layout classes={`bg-about flex flex-col gap-16`}>
      <div className='min-h-screen flex flex-col items-center justify-center'>
        <div id="hero" className="my-20 p-2 flex flex-col gap-4 text-center lg:w-4/6">
          <div className="text-4xl font-semibold">
            ¡Conoce a nuestro equipo!
          </div>
          <div className="text-xl font-medium">
          En nuestra empresa, el éxito se construye con personas apasionadas y dedicadas. Nuestro equipo está formado por profesionales comprometidos con la excelencia, cada uno aportando su experiencia única y entusiasmo. ¡Conócelos a continuación!
          </div>
        </div>
        <div className="flex gap-8 flex-wrap justify-center p-2">
          <TeammateCard name="Bruce Warner" job='CEO & Founder' description='La vida es una lenteja' image={Bruce} />
          <TeammateCard name="Eduardo Rios" job='Contador' description='Por que la tomas o la dejas' image={SuitBruce} />

          <TeammateCard
            name="Manuel Rios"
            job='Software Developer'
            description={
              <div className="flex flex-col">
                <div>Full stack developer, with a background in React, Ruby on Rails, aws.</div>
                <a
                  className="hover:text-gray-400 font-semibold duration-150"
                  href="https://riosoft.xyz"
                  target="_blank"
                  rel="noreferrer noopener"
                >My Website</a>
              </div>
            }
            image={CascoBruce}
          />
        </div>
      </div>
    </Layout>
  );
}

export default TeamView;
